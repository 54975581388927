import { gql } from "graphql-tag";

export const CreateUser = gql`
  mutation addUser($data: UserCreateInput!, $password: String!) {
    addUser(data: $data, password: $password) {
      id
      is_rights_admin
      user_rights
      rights {
        id
        status
        client_id
        site_id
        name
        default
        is_template
      }
      client_id
      client {
        enable_planning
        has_planning
        id
        logo_url
        name
        slug
      }
      signature
      site_id
      site {
        id
        name
        settings_versatility_user_calendar
        settings_planning_archived_employees
        settings_lastname_first
        client_id
        settings_already_assigned_score
        settings_skills_clearances_score
        team_id_for_new_employee
        settings_absent_score
        settings_shift_score
        settings_skills_end_date
        settings_training_score
        settings_training_time
        settings_hide_download_content
        settings_workload
        settings_planning_beta
        settings_monitoring_main_goal
        timezone
        status
        settings_show_score
        settings_enable_imports
        settings_restriction_score
        settings_versatilty_feature
        settings_only_trainer
        settings_only_evaluator
        external_id
        settings_without_shift
        settings_hours_worked
        settings_assignation_per_day
        hours_worked
        rest_time
        assignation_per_day
        settings_content_feature
        settings_content_feature_v2
        settings_position_conflict
        settings_expertise_versatility_score
        settings_contract_score
        settings_force_approbation
      }
      sites {
        id
        name
        client_id
        settings_versatility_user_calendar
        settings_planning_archived_employees
        settings_lastname_first
        settings_already_assigned_score
        settings_skills_clearances_score
        team_id_for_new_employee
        settings_absent_score
        settings_shift_score
        settings_skills_end_date
        settings_training_score
        settings_training_time
        settings_hide_download_content
        settings_workload
        settings_planning_beta
        settings_monitoring_main_goal
        timezone
        status
        settings_show_score
        settings_enable_imports
        settings_restriction_score
        settings_versatilty_feature
        settings_only_trainer
        settings_only_evaluator
        settings_content_feature
        settings_content_feature_v2
        settings_position_conflict
        external_id
        settings_without_shift
        settings_hours_worked
        settings_assignation_per_day
        hours_worked
        rest_time
        assignation_per_day
        settings_expertise_versatility_score
        settings_contract_score
        settings_force_approbation
      }
      first_name
      last_name
      fullname
      avatar_url
      category_job
      language
      role
      job
      email
      enable_expiring_training_notifications
      enable_expiring_clearance_notifications
      enable_expiring_skills_notifications
      enable_validation_training
      enable_team_change
      planning_parameter
      firstname_parameter
      enable_fidelity_notifications
      enable_expiring_contract_notifications
      enable_content_approbation
      enable_content_consultation
      enable_questionnaire_approbation
      favorite_teams
      status
    }
  }
`;

export const UpdateUser = gql`
  mutation customUpdateUser(
    $data: UserUpdateInput!
    $where: UserWhereUniqueInput!
  ) {
    customUpdateUser(data: $data, where: $where) {
      id
      is_rights_admin
      user_rights
      rights {
        id
        status
        client_id
        site_id
        name
        default
        is_template
      }
      teams_as_leader {
        id
        team_name
      }
      limited_access_teams {
        id
        team_name
      }
      client_id
      client {
        enable_planning
        has_planning
        id
        logo_url
        name
        slug
      }
      signature
      site_id
      site {
        id
        rights_admins {
          id
        }
        name
        client_id
        settings_versatility_user_calendar
        settings_planning_archived_employees
        settings_lastname_first
        settings_already_assigned_score
        settings_skills_clearances_score
        team_id_for_new_employee
        settings_absent_score
        settings_shift_score
        settings_skills_end_date
        settings_training_score
        settings_training_time
        settings_hide_download_content
        settings_workload
        settings_planning_beta
        settings_monitoring_main_goal
        timezone
        status
        settings_show_score
        settings_enable_imports
        settings_restriction_score
        settings_versatilty_feature
        settings_only_trainer
        settings_only_evaluator
        external_id
        settings_without_shift
        settings_hours_worked
        settings_assignation_per_day
        hours_worked
        rest_time
        assignation_per_day
        settings_content_feature
        settings_content_feature_v2
        settings_position_conflict
        settings_expertise_versatility_score
        settings_contract_score
        settings_force_approbation
      }
      sites {
        id
        name
        settings_versatility_user_calendar
        settings_planning_archived_employees
        settings_lastname_first
        client_id
        settings_already_assigned_score
        settings_skills_clearances_score
        team_id_for_new_employee
        settings_absent_score
        settings_shift_score
        settings_skills_end_date
        settings_training_score
        settings_training_time
        settings_hide_download_content
        settings_workload
        settings_planning_beta
        settings_monitoring_main_goal
        timezone
        status
        settings_show_score
        settings_enable_imports
        settings_restriction_score
        settings_versatilty_feature
        settings_only_trainer
        settings_only_evaluator
        settings_content_feature
        settings_content_feature_v2
        settings_position_conflict
        external_id
        settings_without_shift
        settings_hours_worked
        settings_assignation_per_day
        hours_worked
        rest_time
        assignation_per_day
        settings_expertise_versatility_score
        settings_contract_score
        settings_force_approbation
      }
      first_name
      last_name
      fullname
      avatar_url
      category_job
      language
      role
      job
      email
      enable_expiring_training_notifications
      enable_expiring_clearance_notifications
      enable_expiring_skills_notifications
      enable_validation_training
      enable_team_change
      planning_parameter
      firstname_parameter
      enable_fidelity_notifications
      enable_expiring_contract_notifications
      enable_content_approbation
      enable_content_consultation
      enable_questionnaire_approbation
      favorite_teams
      status
    }
  }
`;

export const DeleteUser = gql`
  mutation custom_DeleteUser(
    $data: UserUpdateInput!
    $where: UserWhereUniqueInput!
  ) {
    custom_DeleteUser(data: $data, where: $where) {
      id
      is_rights_admin
      user_rights
      rights {
        id
        status
        client_id
        site_id
        name
        default
        is_template
      }
      teams_as_leader {
        id
        team_name
      }
      limited_access_teams {
        id
        team_name
      }
      client_id
      client {
        enable_planning
        has_planning
        id
        logo_url
        name
        slug
      }
      signature
      site_id
      site {
        id
        rights_admins {
          id
        }
        name
        client_id
        settings_versatility_user_calendar
        settings_planning_archived_employees
        settings_lastname_first
        settings_already_assigned_score
        settings_skills_clearances_score
        team_id_for_new_employee
        settings_absent_score
        settings_shift_score
        settings_skills_end_date
        settings_training_score
        settings_training_time
        settings_hide_download_content
        settings_workload
        settings_planning_beta
        settings_monitoring_main_goal
        timezone
        status
        settings_show_score
        settings_enable_imports
        settings_restriction_score
        settings_versatilty_feature
        settings_only_trainer
        settings_only_evaluator
        external_id
        settings_without_shift
        settings_hours_worked
        settings_assignation_per_day
        hours_worked
        rest_time
        assignation_per_day
        settings_content_feature
        settings_content_feature_v2
        settings_position_conflict
        settings_expertise_versatility_score
        settings_contract_score
        settings_force_approbation
      }
      sites {
        id
        name
        settings_versatility_user_calendar
        settings_planning_archived_employees
        settings_lastname_first
        client_id
        settings_already_assigned_score
        settings_skills_clearances_score
        team_id_for_new_employee
        settings_absent_score
        settings_shift_score
        settings_skills_end_date
        settings_training_score
        settings_training_time
        settings_hide_download_content
        settings_workload
        settings_planning_beta
        settings_monitoring_main_goal
        timezone
        status
        settings_show_score
        settings_enable_imports
        settings_restriction_score
        settings_versatilty_feature
        settings_only_trainer
        settings_only_evaluator
        settings_content_feature
        settings_content_feature_v2
        settings_position_conflict
        external_id
        settings_without_shift
        settings_hours_worked
        settings_assignation_per_day
        hours_worked
        rest_time
        assignation_per_day
        settings_expertise_versatility_score
        settings_contract_score
        settings_force_approbation
      }
      first_name
      last_name
      fullname
      avatar_url
      category_job
      language
      role
      job
      email
      enable_expiring_training_notifications
      enable_expiring_clearance_notifications
      enable_expiring_skills_notifications
      enable_validation_training
      enable_team_change
      planning_parameter
      firstname_parameter
      enable_fidelity_notifications
      enable_expiring_contract_notifications
      enable_content_approbation
      enable_content_consultation
      enable_questionnaire_approbation
      favorite_teams
      status
    }
  }
`;

export const Users = gql`
  query Users {
    Users {
      id
      is_rights_admin
      user_rights
      rights {
        id
        status
        client_id
        site_id
        name
        default
        is_template
      }
      teams_as_leader {
        id
        team_name
      }
      limited_access_teams {
        id
        team_name
      }
      client_id
      client {
        enable_planning
        has_planning
        id
        logo_url
        name
        slug
      }
      signature
      site_id
      site {
        id
        rights_admins {
          id
        }
        name
        settings_versatility_user_calendar
        settings_planning_archived_employees
        settings_lastname_first
        client_id
        settings_already_assigned_score
        settings_skills_clearances_score
        team_id_for_new_employee
        settings_absent_score
        settings_shift_score
        settings_skills_end_date
        settings_training_score
        settings_training_time
        settings_hide_download_content
        settings_workload
        settings_planning_beta
        settings_monitoring_main_goal
        timezone
        status
        settings_show_score
        settings_enable_imports
        settings_restriction_score
        settings_versatilty_feature
        settings_only_trainer
        settings_only_evaluator
        external_id
        settings_without_shift
        settings_hours_worked
        settings_assignation_per_day
        hours_worked
        rest_time
        assignation_per_day
        settings_content_feature
        settings_content_feature_v2
        settings_position_conflict
        settings_expertise_versatility_score
        settings_contract_score
        settings_force_approbation
      }
      sites {
        id
        name
        settings_versatility_user_calendar
        settings_planning_archived_employees
        settings_lastname_first
        client_id
        settings_already_assigned_score
        settings_skills_clearances_score
        team_id_for_new_employee
        settings_absent_score
        settings_shift_score
        settings_skills_end_date
        settings_training_score
        settings_training_time
        settings_hide_download_content
        settings_workload
        settings_planning_beta
        settings_monitoring_main_goal
        timezone
        status
        settings_show_score
        settings_enable_imports
        settings_restriction_score
        settings_versatilty_feature
        settings_only_trainer
        settings_only_evaluator
        settings_content_feature
        settings_content_feature_v2
        settings_position_conflict
        external_id
        settings_without_shift
        settings_hours_worked
        settings_assignation_per_day
        hours_worked
        rest_time
        assignation_per_day
        settings_expertise_versatility_score
        settings_contract_score
        settings_force_approbation
      }
      first_name
      last_name
      fullname
      avatar_url
      category_job
      language
      role
      job
      email
      enable_expiring_training_notifications
      enable_expiring_clearance_notifications
      enable_expiring_skills_notifications
      enable_validation_training
      enable_team_change
      planning_parameter
      firstname_parameter
      enable_fidelity_notifications
      enable_expiring_contract_notifications
      enable_content_approbation
      enable_content_consultation
      enable_questionnaire_approbation
      favorite_teams
      status
    }
  }
`;
