import type { TranslateResult } from "vue-i18n";

export const slugify = (text: string) => {
  return text
    .toString() // Cast to string
    .toLowerCase() // Convert the string to lowercase letters
    .normalize("NFD") // The normalize() method returns the Unicode Normalization Form of a given string.
    .trim() // Remove whitespace from both sides of a string
    .replace(/\s+/g, "-") // Replace spaces with -
    .replace(/[^\w-]+/g, "") // Remove all non-word chars
    .replace(/--+/g, "-"); // Replace multiple - with single -
};

export const capitalizeFirstLetter = (text: string): string => {
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const textEllipsis = (name: string, charNb: number) => {
  return name.length > charNb ? `${name.slice(0, charNb)} ...` : name;
};

export const textCut = (name: string, charNb: number) => {
  return name.length > charNb ? `${name.slice(0, charNb)}` : name;
};

export const textEllipsisAbbr = (name: string, charNb: number) => {
  return name.length > charNb ? `${name.slice(0, 1).toUpperCase()}.` : name;
};

export const makeFirstLetterUppercase = (text: TranslateResult) => {
  const textAsString = text as string;
  return (
    textAsString[0].toUpperCase() + textAsString.slice(1, textAsString.length)
  );
};
