<template>
  <div>
    <MDialog
      :custom-z-index="options.customZIndex"
      :is-open="dialog"
      :is-side-dialog="options.sideDialog"
      :persistent="options.persistent"
      :popin-level="popinLevel"
      :width="width"
      :width-in-percentage="widthInPercentage"
      @clickOutside="dialog = $event"
      v-if="
        popinLevel || popinLevel === 0
          ? useUiStore.$state[`popinToggle${popinLevel}`]
          : true
      "
    >
      <div
        :class="$style.wrapperCard"
        v-if="
          useUiStore.$state[`component${popinLevel}`] &&
          !useUiStore.$state.building
        "
      >
        <component
          :is="useUiStore.$state[`component${popinLevel}`]"
          v-bind="useUiStore.$state[`props${popinLevel}`]"
        />
      </div>
    </MDialog>
  </div>
</template>

<script lang="ts">
import { mapStores } from "pinia";
import Vue from "vue";

import MDialog from "@/components/global/MDialog.vue";
import { type IPopinOptions, useUiStore } from "@/pinia/modules/ui";

export default Vue.extend({
  components: { MDialog },
  computed: {
    dialog: {
      get(): boolean {
        const dialogValue =
          this.useUiStore.$state[`popinToggle${this.popinLevel}`];
        return dialogValue;
      },
      set(newBoolean: boolean): void {
        if (
          newBoolean === false &&
          this.useUiStore.$state[`options${this.popinLevel}`].forceDestroy
        ) {
          this.useUiStore.closePopinAndDestroy(this.popinLevel);
        } else {
          this.useUiStore.$state[`popinToggle${this.popinLevel}`] = newBoolean;
        }
      },
    },
    options(): IPopinOptions {
      return this.useUiStore.$state[`options${this.popinLevel}`];
    },
    width(): number | string {
      if (this.options.widthInPercentage) {
        return this.options.width || "100";
      }
      if (!this.options.width) {
        return this.options.sideDialog ? "450" : "600";
      }
      if (!this.options.width && this.options.sideDialog) {
        return "450";
      }
      if (this.options.sideDialog) {
        return this.options.width ? this.options.width : "450";
      }
      return this.options.width;
    },
    widthInPercentage(): boolean {
      if (!this.options.widthInPercentage) return false;
      return true;
    },
    ...mapStores(useUiStore),
  },
  name: "GlobalDialog",
  props: {
    popinLevel: {
      default: 0,
      required: false,
      type: Number,
    },
  },
});
</script>

<style lang="scss" module>
.dialog {
  margin: 200px;
  background-color: white;
  // overflow: visible !important;
}
.wrapperCard,
.drawer {
  height: 100%;
}
.loading {
  padding: 50px;
}
.sideDialog {
  background-color: white;
  margin: 0 !important;
  padding: 0;
  position: fixed;
  max-height: 100% !important;
  height: 100% !important;
  right: 0 !important;
}
</style>
