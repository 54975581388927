import { gql } from "graphql-tag";
import { isEmpty } from "lodash";

import type {
  SkillID,
  ClearanceID,
  RestrictionID,
  KeywordID,
  TeamID,
} from "@/tscript/mercateam";

export const CreateEmployee = gql`
  mutation CreateEmployee($data: EmployeeCreateInput!) {
    createEmployee(data: $data) {
      id
      email
      phone
      first_name
      last_name
      picture_url
      employee_company_id
      birthday_date
      end_date_contract
      start_date
      fullname
      comment
      age
      seniority
      contract_expired
      status
      team_id
      team {
        team_name
        factory_id
      }
      client_id
      site_id
      contract_id
      contract {
        id
        contract_name
        expires
        color_bg
        color_text
        status
        start_date
        end_date
      }
      job
      category_id
      categories {
        id
        name
      }
      site_id
      keywords_temp
      link_skills {
        id
        skill_id
        level
        trainer
      }
      link_trainings {
        id
        skill_id
        training_level
        validated
      }
      gender
      link_contracts {
        id
        start_date
        end_date
        comment
        employee_id
        noticeDate
        contract_tag_id
        contract_tag {
          value
        }
        contract_id
        contract {
          id
          contract_name
          expires
          color_bg
          color_text
          status
        }
      }
      internal_id
      skillsEvaluatedBy {
        id
        name
      }
      skillsTrainedBy {
        id
        name
      }
      function_sheet_id
      function_sheet {
        id
      }
    }
  }
`;

export const UpdateEmployee = gql`
  mutation UpdateEmployee(
    $data: EmployeeUpdateInput!
    $where: EmployeeWhereUniqueInput!
  ) {
    updateEmployee(data: $data, where: $where) {
      id
      email
      phone
      first_name
      last_name
      picture_url
      employee_company_id
      birthday_date
      end_date_contract
      start_date
      fullname
      comment
      age
      seniority
      contract_expired
      status
      team_id
      team {
        team_name
        factory_id
      }
      client_id
      site_id
      contract_id
      contract {
        id
        contract_name
        expires
        color_bg
        color_text
        status
        start_date
        end_date
      }
      job
      category_id
      categories {
        id
        name
      }
      keywords_temp
      link_skills {
        id
        skill_id
        level
        trainer
      }
      link_trainings {
        id
        skill_id
        training_level
        validated
      }
      gender
      link_contracts {
        id
        start_date
        end_date
        comment
        employee_id
        noticeDate
        contract_tag_id
        contract_tag {
          value
        }
        contract_id
        contract {
          id
          contract_name
          expires
          color_bg
          color_text
          status
        }
      }
      internal_id
      skillsEvaluatedBy {
        id
        name
      }
      skillsTrainedBy {
        id
        name
      }
      function_sheet_id
      function_sheet {
        id
      }
    }
  }
`;

export const DeleteEmployee = gql`
  mutation DeleteEmployee($where: EmployeeWhereUniqueInput!) {
    deleteEmployee(where: $where) {
      id
    }
  }
`;

export const Employees = gql`
  query Employees(
    $orderBy: [EmployeeOrderByWithRelationAndSearchRelevanceInput!]
  ) {
    Employees(orderBy: $orderBy) {
      id
      email
      phone
      first_name
      last_name
      picture_url
      employee_company_id
      birthday_date
      end_date_contract
      start_date
      fullname
      comment
      age
      seniority
      contract_expired
      status
      team_id
      team {
        team_name
        factory_id
      }
      client_id
      site_id
      contract_id
      contract {
        id
        contract_name
        expires
        color_bg
        color_text
        status
        start_date
        end_date
      }
      job
      category_id
      categories {
        id
        name
        color_bg
        color_text
      }
      site_id
      keywords_temp
      link_skills {
        id
        status
        skill_id
        level
        trainer
      }
      link_trainings {
        id
        status
        skill_id
        training_level
        validated
      }
      gender
      link_contracts {
        id
        start_date
        end_date
        comment
        employee_id
        noticeDate
        contract_tag_id
        contract_tag {
          value
        }
        contract_id
        contract {
          id
          contract_name
          expires
          color_bg
          color_text
          status
        }
      }
      function_sheet_id
      function_sheet {
        id
      }
      internal_id
      skillsEvaluatedBy {
        id
        name
      }
      skillsTrainedBy {
        id
        name
      }
    }
  }
`;

export const EmployeesCards = gql`
  query EmployeesCards(
    $where: EmployeeWhereInput
    $linkSkillsWhere: LSkillWhereInput
    $linkTrainingsWhere: LTrainingWhereInput
    $linkClearancesWhere: LClearanceWhereInput
    $orderBy: [EmployeeOrderByWithRelationAndSearchRelevanceInput!]
    $linkClearancesOrderBy2: [LClearanceOrderByWithRelationAndSearchRelevanceInput!]
    $linkSkillsOrderBy2: [LSkillOrderByWithRelationAndSearchRelevanceInput!]
  ) {
    Employees(where: $where, orderBy: $orderBy) {
      id
      first_name
      last_name
      fullname
      comment
      picture_url
      site_id
      team_id
      employee_company_id
      job
      keywords_temp
      categories {
        name
        color_bg
        color_text
      }
      contract {
        id
        contract_name
        expires
        color_bg
        color_text
        status
        start_date
        end_date
      }
      team {
        team_name
        factory_id
      }
      link_skills(where: $linkSkillsWhere, orderBy: $linkSkillsOrderBy2) {
        id
        status
        skill {
          name
        }
        skill_id
        level
        will_expire
        expired
        end_date
      }
      link_clearances(
        where: $linkClearancesWhere
        orderBy: $linkClearancesOrderBy2
      ) {
        id
        status
        clearance {
          name
        }
        clearance_id
        will_expire
        expired
        end_date
      }
      link_trainings(where: $linkTrainingsWhere) {
        id
        status
        clearance {
          name
        }
        skill {
          name
        }
        skill_id
        clearance_id
        training_level
        end_date
      }
      gender
      internal_id
      skillsEvaluatedBy {
        id
        name
      }
      skillsTrainedBy {
        id
        name
      }
    }
  }
`;

export const DeleteManyEmployee = gql`
  mutation DeleteManyEmployee($where: EmployeeWhereInput) {
    deleteManyEmployee(where: $where) {
      count
    }
  }
`;

export const EmployeesCards_keyword_nested = gql`
  query EmployeesCards_keyword_nested(
    $where: EmployeeWhereInput
    $orderBy: [EmployeeOrderByWithRelationAndSearchRelevanceInput!]
    $linkClearancesOrderBy2: [LClearanceOrderByWithRelationAndSearchRelevanceInput!]
    $linkSkillsOrderBy2: [LSkillOrderByWithRelationAndSearchRelevanceInput!]
  ) {
    Employees(where: $where, orderBy: $orderBy) {
      id
      first_name
      last_name
      fullname
      comment
      picture_url
      site_id
      team_id
      employee_company_id
      job
      keywords_temp
      contract {
        id
        contract_name
        expires
        color_bg
        color_text
        status
        start_date
        end_date
      }
      team {
        team_name
        factory_id
      }
      link_skills(orderBy: $linkSkillsOrderBy2) {
        id
        status
        skill {
          name
        }
        skill_id
        level
        will_expire
        expired
        end_date
      }
      link_clearances(orderBy: $linkClearancesOrderBy2) {
        id
        status
        clearance {
          name
        }
        clearance_id
        will_expire
        expired
        end_date
      }
      link_trainings {
        id
        status
        clearance {
          name
        }
        skill {
          name
        }
        skill_id
        clearance_id
        training_level
        end_date
      }
      gender
      internal_id
    }
  }
`;

export const getEmployeesCards_variables = (
  whereType:
    | "CLEARANCE_KEYWORD_NESTED"
    | "EMPLOYEE_KEYWORD"
    | "SKILL_KEYWORD_LINKED"
    | "SKILL_KEYWORD_NESTED",
  searchType: "AND" | "OR",
  selectedSkillsList: SkillID[],
  selectedClearancesList: ClearanceID[],
  selectedRestrictionsList: RestrictionID[],
  selectedKeywordsList: KeywordID[],
  selectedTeamsList: TeamID[],
) => {
  let where: unknown = null;
  switch (whereType) {
    case "SKILL_KEYWORD_NESTED":
      where = getEmloyeesCards_where_skillKeywordNested(selectedKeywordsList);
      break;
    case "CLEARANCE_KEYWORD_NESTED":
      where =
        getEmloyeesCards_where_clearanceKeywordNested(selectedKeywordsList);
      break;
    case "SKILL_KEYWORD_LINKED":
      where = getEmloyeesCards_where_skillKeywordLinked(selectedKeywordsList);
      break;
    default:
      where = getEmloyeesCards_where_employeeKeyword(
        searchType,
        selectedSkillsList,
        selectedClearancesList,
        selectedRestrictionsList,
        selectedKeywordsList,
        selectedTeamsList,
      );
  }

  const whereOptions = {
    linkClearancesWhere: {
      status: {
        equals: "active",
      },
    },
    linkSkillsWhere: {
      status: {
        equals: "active",
      },
    },
    linkTrainingsWhere: {
      status: {
        equals: "active",
      },
      validated: {
        equals: false,
      },
    },
  };

  const orderOptions = {
    linkClearancesOrderBy2: [
      {
        clearance: {
          name: "asc",
        },
      },
    ],
    linkSkillsOrderBy2: [
      {
        skill: {
          name: "asc",
        },
      },
    ],
    orderBy: [
      {
        first_name: "asc",
      },
    ],
  };

  if (whereType === "EMPLOYEE_KEYWORD")
    return {
      where,
      ...whereOptions,
      orderOptions,
    };

  return {
    orderOptions,
    where,
  };
};

const getEmloyeesCards_where_employeeKeyword = (
  searchType: "AND" | "OR",
  selectedSkillsList: SkillID[],
  selectedClearancesList: ClearanceID[],
  selectedRestrictionsList: RestrictionID[],
  selectedKeywordsList: KeywordID[],
  selectedTeamsList: TeamID[],
) => {
  let res: any = {
    AND: [
      {
        status: {
          equals: "active",
        },
      },
    ],
  };
  if (!isEmpty(selectedKeywordsList)) {
    res = {
      AND: [
        {
          keywords_temp: {
            [`${searchType === "OR" ? "hasSome" : "hasEvery"}`]:
              selectedKeywordsList,
          },
          status: {
            equals: "active",
          },
        },
      ],
    };
  }

  const linkSkills: {
    link_skills: {
      some: {
        skill_id: {
          equals?: string;
          in?: string[];
        };
        status: {
          equals: string;
        };
      };
    };
  }[] = [];
  const linkClearances: {
    link_clearances: {
      some: {
        clearance_id: {
          equals?: string;
          in?: string[];
        };
        status: {
          equals: string;
        };
      };
    };
  }[] = [];
  const linkRestrictions: {
    link_restrictions: {
      every: {
        restriction_id: {
          not: {
            equals?: string;
            in?: string[];
          };
        };
        status: {
          equals: string;
        };
      };
    };
  }[] = [];

  if (!isEmpty(selectedRestrictionsList)) {
    for (const restrictionID of selectedRestrictionsList) {
      if (!restrictionID) continue;
      linkRestrictions.push({
        link_restrictions: {
          every: {
            restriction_id: {
              not: {
                equals: restrictionID,
              },
            },
            status: {
              equals: "active",
            },
          },
        },
      });
    }
  }

  if (!isEmpty(selectedSkillsList)) {
    if (searchType === "OR") {
      linkSkills.push({
        link_skills: {
          some: {
            skill_id: {
              in: selectedSkillsList,
            },
            status: {
              equals: "active",
            },
          },
        },
      });
    } else if (searchType === "AND") {
      for (const skillID of selectedSkillsList) {
        linkSkills.push({
          link_skills: {
            some: {
              skill_id: {
                equals: skillID,
              },
              status: {
                equals: "active",
              },
            },
          },
        });
      }
    } else {
      console.error("Invalid searchType");
    }
  }

  if (!isEmpty(selectedClearancesList)) {
    if (searchType === "OR") {
      linkClearances.push({
        link_clearances: {
          some: {
            clearance_id: {
              in: selectedClearancesList,
            },
            status: {
              equals: "active",
            },
          },
        },
      });
    } else if (searchType === "AND") {
      for (const clearanceID of selectedClearancesList) {
        linkClearances.push({
          link_clearances: {
            some: {
              clearance_id: {
                equals: clearanceID,
              },
              status: {
                equals: "active",
              },
            },
          },
        });
      }
    } else {
      console.error("Invalid searchType");
    }
  }

  const linkTeams = isEmpty(selectedTeamsList)
    ? []
    : [
        {
          team_id: {
            in: selectedTeamsList,
          },
        },
      ];

  const searchTypeObject: any = {
    [`${searchType}`]: [],
  };
  searchTypeObject[`${searchType}`].push(...linkSkills, ...linkClearances);
  res.AND.push(...linkRestrictions, searchTypeObject, ...linkTeams);
  return res;
};

const getEmloyeesCards_where_skillKeywordNested = (
  selectedKeywordsList: KeywordID[],
) => {
  return {
    AND: [
      {
        link_skills: {
          some: {
            skill: {
              is: {
                keywords_temp: {
                  hasSome: selectedKeywordsList,
                },
                status: {
                  equals: "active",
                },
              },
            },
            status: {
              equals: "active",
            },
          },
        },
        status: {
          equals: "active",
        },
      },
    ],
  };
};

const getEmloyeesCards_where_clearanceKeywordNested = (
  selectedKeywordsList: KeywordID[],
) => {
  return {
    AND: [
      {
        link_clearances: {
          some: {
            clearance: {
              is: {
                keywords_temp: {
                  hasSome: selectedKeywordsList,
                },
                status: {
                  equals: "active",
                },
              },
            },
            status: {
              equals: "active",
            },
          },
        },
        status: {
          equals: "active",
        },
      },
    ],
  };
};

const getEmloyeesCards_where_skillKeywordLinked = (
  selectedKeywordsList: KeywordID[],
) => {
  return {
    AND: [
      {
        link_skills: {
          some: {
            keywords_temp: {
              hasSome: selectedKeywordsList,
            },
            skill: {
              is: {
                keywords_temp: {
                  hasSome: selectedKeywordsList,
                },
                status: {
                  equals: "active",
                },
              },
            },
            status: {
              equals: "active",
            },
          },
        },
        status: {
          equals: "active",
        },
      },
    ],
  };
};
