import {
  type CollectionReference,
  type DocumentData,
  type DocumentSnapshot,
  type Firestore,
  type OrderByDirection,
  type Query,
  type QuerySnapshot,
  type WhereFilterOp,
  type WriteBatch,
  addDoc,
  collection,
  deleteDoc,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  getDocsFromServer,
  limit,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  startAfter,
  updateDoc,
  where,
  writeBatch,
} from "firebase/firestore";

import db from "@/firebase/db";

import type { Func } from "../../utils/types.utils";

import { BaseStatus } from "../interfaces";
import loggerHelper from "../loggerHelper";

export interface ISearchCriteria {
  fieldName: string;
  filterOp: WhereFilterOp;

  value: any;
}

export default class FirestoreHelper {
  private _errorHandler = async (e: unknown, label: string, id?: string) => {
    if (this.logInfo) {
      await loggerHelper.report(
        `${label} - ${this.collection_name} - ${id || "noid"} - ${e}`,
      );
    }
    console.error(this.collection_name);
    throw new Error(String(e));
  };

  collection_name: string;
  db: Firestore;
  logInfo?: boolean;

  ref: CollectionReference;
  constructor(collection_name: string, logInfo: boolean) {
    this.db = db;
    this.ref = collection(db, collection_name);
    this.collection_name = collection_name;
    this.logInfo = logInfo;
  }

  private async _delete(query: Query) {
    const querySnapShot = await getDocs(query);
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    querySnapShot.forEach(async (_doc) => {
      await deleteDoc(doc(this.db, _doc.ref.path));
    });
  }

  private _getSnapshot<T>(documentSnapShot: DocumentSnapshot): T | null {
    if (!documentSnapShot) return null;
    if (!documentSnapShot.exists) return null;
    if (!documentSnapShot.data()) return null;
    if (!documentSnapShot.id) return null;
    const document = documentSnapShot.data() || {};
    document.id = documentSnapShot.id;
    return document as T;
  }

  private _getSnapshots<T>(querySnapShot: QuerySnapshot): T[] {
    const returnArray: T[] = [];
    querySnapShot.forEach((doc) => {
      if (doc?.exists() && doc.data && doc.id) {
        const document = doc.data();
        document.id = doc.id;
        returnArray.push(document as T);
      }
    });
    return returnArray;
  }

  private _query(_limit?: number, _orderBy?: string) {
    let q = query(this.ref);
    q = _limit ? query(q, limit(_limit)) : q;
    q = _orderBy ? query(q, orderBy(_orderBy)) : q;
    return q;
  }

  private _where(searchCriteria: ISearchCriteria[]): Query {
    let whereQuery = query(this.ref);
    for (const [i] of searchCriteria.entries()) {
      whereQuery = query(
        whereQuery,
        where(
          searchCriteria[i].fieldName,
          searchCriteria[i].filterOp,
          searchCriteria[i].value,
        ),
      );
    }
    return whereQuery;
  }

  async addData<T>(
    data: {
      updated_at: Date;
    } & { created_at: Date } & Omit<T, "created_at" | "id" | "updated_at">,
  ) {
    data.created_at = new Date();
    return await addDoc(this.ref, {
      ...data,
      created_at: new Date(),
    }).catch((e: unknown) => this._errorHandler(e, "addData"));
  }

  // Batched
  async addDocsWithBatch<T>(_docs: { id?: string }[] & T): Promise<
    | {
      batchArray: WriteBatch[];
      documents: DocumentData[];
    }
    | undefined
  > {
    try {
      const batchArray: WriteBatch[] = [];
      const documents: DocumentData[] = [];
      batchArray.push(writeBatch(this.db));
      let operationCounter = 0;
      let batchIndex = 0;

      for (const _doc of _docs) {
        const docRef = _doc.id ? doc(this.ref, _doc.id) : doc(this.ref);
        documents.push({ id: docRef.id, ..._doc });
        batchArray[batchIndex].set(docRef, _doc);
        operationCounter++;
        // https://stackoverflow.com/questions/52165352/how-can-i-update-more-than-500-docs-in-firestore-using-batch
        if (operationCounter === 499) {
          batchArray.push(writeBatch(this.db));
          batchIndex++;
          operationCounter = 0;
        }
      }
      for (const batch of batchArray) {
        await batch.commit();
      }
      return { batchArray, documents };
    } catch (error) {
      await this._errorHandler(error, "addDocsWithBatch");
    }
  }

  async deleteData(id: string) {
    await deleteDoc(doc(this.ref, id)).catch((e: unknown) =>
      this._errorHandler(e, "deleteData", id),
    );
  }

  async deleteDataWithWhere(arrayWhere: ISearchCriteria[]) {
    try {
      const querySnapShot = this._where(arrayWhere);
      await this._delete(querySnapShot);
    } catch (e: unknown) {
      return this._errorHandler(e, "deleteDataWithWhere");
    }
  }

  async deleteDocsWithBatch(ids: string[]) {
    try {
      const batchArray: WriteBatch[] = [];
      batchArray.push(writeBatch(this.db));
      let operationCounter = 0;
      let batchIndex = 0;
      for (const id of ids) {
        const docRef = doc(this.ref, id);
        batchArray[batchIndex].delete(docRef);
        operationCounter++;
        // https://stackoverflow.com/questions/52165352/how-can-i-update-more-than-500-docs-in-firestore-using-batch
        if (operationCounter === 499) {
          batchArray.push(writeBatch(this.db));
          batchIndex++;
          operationCounter = 0;
        }
      }
      for (const batch of batchArray) {
        await batch.commit();
      }
      return batchArray;
    } catch (error) {
      await this._errorHandler(error, "deleteDocsWithBatch");
    }
  }

  // Firestore rules are stronger than getDocuments() query.
  async getDocument<T>(docId: string): Promise<T | null> {
    try {
      const querySnapShot = await getDoc(doc(this.ref, docId));
      return this._getSnapshot<T>(querySnapShot);
    } catch (e: unknown) {
      return this._errorHandler(e, "getDocument");
    }
  }

  async getDocumentIdsWithWhere(
    arrayWhere: ISearchCriteria[],
    _limit?: number,
    _orderBy?: string,
    _order?: OrderByDirection,
  ): Promise<string[]> {
    try {
      let whereQuery = this._where(arrayWhere);
      whereQuery = _limit ? query(whereQuery, limit(_limit)) : whereQuery;
      whereQuery = _orderBy
        ? query(whereQuery, orderBy(_orderBy, _order || "asc"))
        : whereQuery;
      const querySnapShot = await getDocs(whereQuery);
      const ids: string[] = [];
      querySnapShot.forEach((doc) => {
        ids.push(doc.id);
      });
      return ids;
    } catch (e: unknown) {
      return this._errorHandler(e, "getDocumentIdsWithWhere");
    }
  }

  // If the query doesn't working, it's probably because of your firestore rules.
  async getDocuments<T>(limit?: number, orderBy?: string): Promise<T[]> {
    try {
      const query = this._query(limit, orderBy);
      const querySnapShot = await getDocsFromServer(query);
      return this._getSnapshots<T>(querySnapShot);
    } catch (e) {
      return this._errorHandler(e, "getDocuments");
    }
  }

  async getDocumentsWhereWithCount(
    arrayWhere: ISearchCriteria[],
  ): Promise<number> {
    const whereQuery = this._where(arrayWhere);

    const count: any = await getCountFromServer(whereQuery);
    const countValue = count?._data?.count?.integerValue
      ? Number(count?._data?.count?.integerValue)
      : 0;
    return countValue;
  }

  async getDocumentsWithStringSearchWhere<T>(
    arrayWhere: ISearchCriteria[],
    fieldString: string,
    searchString: string,
    _limit?: number,
  ): Promise<T[]> {
    try {
      let whereQuery = this._where(arrayWhere);
      whereQuery = _limit ? query(whereQuery, limit(_limit)) : whereQuery;
      whereQuery = query(whereQuery, orderBy(fieldString, "asc"));
      whereQuery = query(whereQuery, where(fieldString, ">=", searchString));
      whereQuery = query(
        whereQuery,
        where(fieldString, "<=", `${searchString}\uf8ff`),
      );
      const querySnapShot = await getDocsFromServer(whereQuery);
      return this._getSnapshots<T>(querySnapShot);
    } catch (e: unknown) {
      return this._errorHandler(e, "getData");
    }
  }

  async getDocumentsWithWhere<T>(
    arrayWhere: ISearchCriteria[],
    _limit?: number,
    _orderBy?: string,
    _order?: OrderByDirection,
  ): Promise<T[]> {
    try {
      let whereQuery = this._where(arrayWhere);
      whereQuery = _limit ? query(whereQuery, limit(_limit)) : whereQuery;
      whereQuery = _orderBy
        ? query(whereQuery, orderBy(_orderBy, _order || "asc"))
        : whereQuery;
      const querySnapShot = await getDocsFromServer(whereQuery);
      return this._getSnapshots<T>(querySnapShot);
    } catch (e: unknown) {
      return this._errorHandler(e, "getData");
    }
  }

  async getDocumentsWithWhereWithCount<T>(
    arrayWhere: ISearchCriteria[],
    _limit?: number,
    _orderBy?: string,
    _order?: OrderByDirection,
  ): Promise<{ count: number; items: T[] }> {
    try {
      let whereQuery = this._where(arrayWhere);

      const count: any = await getCountFromServer(whereQuery);
      whereQuery = _limit ? query(whereQuery, limit(_limit)) : whereQuery;
      whereQuery = _orderBy
        ? query(whereQuery, orderBy(_orderBy, _order || "asc"))
        : whereQuery;
      const querySnapShot = await getDocs(whereQuery);
      return {
        count: count?._data?.count?.integerValue || 0,
        items: this._getSnapshots<T>(querySnapShot),
      };
    } catch (e: unknown) {
      return this._errorHandler(e, "getData");
    }
  }

  async getDocumentsWithWhereWithCountWithPagination<T>(
    arrayWhere: ISearchCriteria[],
    _orderBy?: string,
    _order?: OrderByDirection,
    _elementsPerPage?: number,
    startAfterDoc?: DocumentSnapshot<DocumentData>,
  ): Promise<{
      count: number;
      items: T[];
      lastDoc: DocumentSnapshot<DocumentData>;
    }> {
    try {
      const _limit = _elementsPerPage || 10;
      let whereQuery = this._where(arrayWhere);

      const count: any = await getCountFromServer(whereQuery);
      whereQuery = _orderBy
        ? query(whereQuery, orderBy(_orderBy, _order || "asc"), limit(_limit))
        : whereQuery;
      if (startAfterDoc) {
        whereQuery = query(whereQuery, startAfter(startAfterDoc));
      }
      const querySnapShot = await getDocs(whereQuery);
      return {
        count: count?._data?.count?.integerValue
          ? Number(count?._data?.count?.integerValue)
          : 0,
        items: this._getSnapshots<T>(querySnapShot),
        lastDoc: querySnapShot.docs[querySnapShot.docs.length - 1],
      };
    } catch (e: unknown) {
      return this._errorHandler(e, "getData");
    }
  }

  // Subscriber / Unsubscribe
  onSnapshotWhere<T extends { id: string; status: BaseStatus }>(
    searchCriteria: ISearchCriteria[],
    callback: {
      queue: string[];

      update: Func;
    },
    _limit?: number,
    _orderBy?: string,
  ) {
    let whereQuery = this._where(searchCriteria);
    whereQuery = _limit ? query(whereQuery, limit(_limit)) : whereQuery;
    whereQuery = _orderBy ? query(whereQuery, orderBy(_orderBy)) : whereQuery;

    return onSnapshot(whereQuery, (querySnapshot) => {
      const documents: T[] = [];
      const actives: T[] = [];
      const archived: T[] = [];
      const deleted: T[] = [];
      querySnapshot.forEach((doc) => {
        const document = {
          ...doc.data(),
          id: doc.id,
        } as T;
        documents.push(document);

        if (document.status === BaseStatus.archived) {
          archived.push(document);
        } else if (
          document.status === BaseStatus.deleted ||
          (document.status as any) === "deleted"
        ) {
          deleted.push(document);
        } else {
          actives.push(document);
        }
      });
      callback.update(documents, actives, archived, deleted);
    });
  }

  async setData<T>(id: string, data: Omit<T, "id">) {
    await setDoc(doc(this.ref, id), data, { merge: true }).catch(
      (e: unknown) => this._errorHandler(e, "setData", id),
    );
  }

  async updateData<T>(id: string, data: Partial<T>) {
    await updateDoc(doc(this.ref, id), {
      ...data,
      updated_at: new Date(),
    }).catch((e: unknown) => this._errorHandler(e, "updateData", id));
  }

  async updateDocsWithBatch<T extends { id: string }>(_docs: T[]) {
    try {
      const batchArray: WriteBatch[] = [];
      batchArray.push(writeBatch(this.db));
      let operationCounter = 0;
      let batchIndex = 0;

      for (const _doc of _docs) {
        const docRef = doc(this.ref, _doc.id);
        batchArray[batchIndex].update(docRef, _doc);
        operationCounter++;
        // https://stackoverflow.com/questions/52165352/how-can-i-update-more-than-500-docs-in-firestore-using-batch
        if (operationCounter === 499) {
          batchArray.push(writeBatch(this.db));
          batchIndex++;
          operationCounter = 0;
        }
      }
      for (const batch of batchArray) {
        await batch.commit();
      }
      return batchArray;
    } catch (error) {
      await this._errorHandler(error, "updateDocsWithBatch");
    }
  }
}
