/* eslint-disable */
/* prettier-ignore */
import {RumInitConfiguration} from "@datadog/browser-rum";
import {version} from "../../package.json";
import {ServeConfig} from "./utils";

export const DISPLAY_CALENDAR = false;
export const IS_DEV = false;
export const IS_DEMO = false;
export const IS_STAGING = false;
export const IS_PROD = true;
export const IS_TEST = false;
export const BACKEND_GRAPHQL_URL =  import.meta.env.VITE_BACKEND_URL;
export const BACKEND_CLOUD_RUN_URL = import.meta.env.VITE_BACKEND_URL;


export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyAwAR64l2_lCUB1h8eMBPtw8lW_v4mZka8",
  authDomain: "mercateam-prod.firebaseapp.com",
  databaseURL: "https://mercateam-prod.firebaseio.com",
  projectId: "mercateam-prod",
  storageBucket: "mercateam-prod.appspot.com",
  messagingSenderId: "966575276781",
  appId: "1:966575276781:web:e55199d63f2821d09f02e4",
  measurementId: "G-7ZLV03M9SS",
};

export const config: ServeConfig = {
  useLocalFirestore: false,
  useLocalAuthentification: false,
  useLocalFunctions: false,
  useLocalBackend: false,
  logLevel: "silent",
  mode: "production",
};

export const DATADOG: RumInitConfiguration = {
  applicationId: '0a1c9ea9-c37c-405d-a28e-76da6e0871af',
  clientToken: 'pub510e65142f98e62029c5dfef2387d382',
  site: 'datadoghq.eu',
  service:'mercateam-prod',
  version,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 100,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel:'allow'
};

