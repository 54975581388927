<template>
  <div>
    <div
      :id="`myModal_${props.popinLevel}`"
      :style="`z-index: ${customZIndex}`"
      class="modal d-flex align-center justify-center"
      v-if="isOpen"
    >
      <div
        :class="[
          { modalOpen: isOpenForAnimation },
          { sideDialog: isSideDialog },
        ]"
        :style="`width: ${width}${widthInPercentage ? '%' : 'px'}`"
        class="modalContent"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { type PropType, defineComponent, ref, toRefs, watchEffect } from "vue";

export default defineComponent({
  name: "MDialog",
  props: {
    buttonText: {
      default: "Open Modal",
      type: String as PropType<string>,
    },
    closeButton: {
      default: false,
      type: Boolean as PropType<boolean>,
    },
    customZIndex: {
      default: 8,
      type: Number as PropType<number>,
    },
    isOpen: {
      default: false,
      type: Boolean as PropType<boolean>,
    },
    isSideDialog: {
      default: false,
      type: Boolean as PropType<boolean>,
    },
    persistent: {
      default: false,
      type: Boolean as PropType<boolean>,
    },
    popinLevel: {
      default: 0,
      type: Number as PropType<number>,
    },
    width: {
      default: "500",
      type: String as PropType<string>,
    },
    widthInPercentage: {
      default: false,
      type: Boolean as PropType<boolean>,
    },
  },
  setup(props, context) {
    // When the user clicks anywhere outside of the modal, close it
    const { popinLevel: level } = toRefs(props);

    window.addEventListener("click", (event) => {
      const modal = document.getElementById(`myModal_${level.value}`);

      if (event.target === modal && !props.persistent) {
        context.emit("clickOutside", false);
      }
    });

    const openDialog = () => {
      context.emit("update:isOpen", true);
    };

    const closeDialog = () => {
      context.emit("update:isOpen", false);
    };

    const isOpenForAnimation = ref(false);
    const { isOpen: isOpenRef } = toRefs(props);
    watchEffect(() => {
      setTimeout(() => {
        isOpenForAnimation.value = isOpenRef.value;
      }, 10);
    });

    return { closeDialog, isOpenForAnimation, level, openDialog, props };
  },
});
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  top: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}

.modalContent {
  position: absolute;
  background-color: #fefefe;
  transition: right ease-in-out 0.2s;
  border-radius: 0.25rem;

  &.sideDialog {
    height: 100vh;
    top: 0;
    // @Fernando J'ai commenté car ça pêtait la popin d'édition du planning
    // overflow: auto;

    &:not(.modalOpen) {
      right: -600px;
    }
    &.modalOpen {
      right: 0;
    }
  }

  &:not(.sideDialog) {
    max-height: 90vh;
    overflow: auto;
  }
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
</style>
