<template>
  <v-avatar v-bind="$attrs" :color="color" :size="props.size" class="mr-3">
    <v-img
      :alt="`${props.firstName} ${props.lastName}`"
      :src="props.pictureUrl"
      v-if="props.pictureUrl"
    ></v-img>
    <span :style="`font-size: ${size / 2}px`" class="white--text" v-else>
      {{ initials }}
    </span>
  </v-avatar>
</template>

<script lang="ts">
import { type PropType, computed, defineComponent } from "vue";

import { stringToColor } from "@/tscript/utils";

export default defineComponent({
  props: {
    firstName: {
      default: null,
      type: String as PropType<string>,
    },
    id: {
      default: null,
      type: String as PropType<string>,
    },
    lastName: {
      default: null,
      type: String as PropType<string>,
    },
    pictureUrl: {
      default: null,
      type: String as PropType<string>,
    },
    size: {
      default: 24,
      type: Number as PropType<number>,
    },
  },
  setup(props) {
    const color = computed(() =>
      stringToColor(props.id ?? `${props.firstName} + ${props.lastName}`),
    );
    const initials = computed(() => {
      if (props.firstName?.length > 0 && props.lastName?.length > 0) {
        return props.firstName?.charAt(0) + props.lastName?.charAt(0);
      }
      return "--";
    });
    return {
      color,
      initials,
      props,
    };
  },
});
</script>
