import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import { type User, getAuth } from "firebase/auth";
import Vue from "vue";
import VueApollo from "vue-apollo";
import { createApolloClient } from "vue-cli-plugin-apollo/graphql-client";

import { BACKEND_GRAPHQL_URL } from "./config";

// Install the vue plugin
Vue.use(VueApollo);

// Name of the localStorage item
const AUTH_TOKEN = "apollo-token";

// Http endpoint
const httpEndpoint = `${
  BACKEND_GRAPHQL_URL ?? "http://localhost:4000"
}/graphql`;

// Files URL root
const filesRoot =
  import.meta.env.VUE_APP_FILES_ROOT ||
    httpEndpoint.substring(0, httpEndpoint.indexOf("/graphql"));

Vue.prototype.$filesRoot = filesRoot;

export const getToken = async (): Promise<string | undefined> => {
  const user: User | null = getAuth().currentUser;
  const token: string | undefined = await user?.getIdToken();
  if (token) {
    localStorage.setItem(AUTH_TOKEN, token);
  }
  return token;
};

// Get headers
const headersLink = setContext(async (_, { headers }) => {
  const token = await getToken();

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

// Config
const defaultOptions = {
  // Override default cache
  cache: new InMemoryCache({
    addTypename: false,
  }),
  // You can use `wss` for secure connection (recommended in production)
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // httpLinkOptions property of defaultOptions.
  link: headersLink,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // Is being rendered on the server?
  ssr: false,
  // LocalStorage token
  tokenName: AUTH_TOKEN,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,

  // Use `null` to disable subscriptions
  wsEndpoint: null,
};

// Create apollo client
export const { apolloClient /* , wsClient */ } = createApolloClient({
  ...defaultOptions,
});

// Create vue apollo provider
export const apolloProvider = new VueApollo({
  defaultClient: apolloClient,
  defaultOptions: {
    $query: {
      fetchPolicy: "cache-and-network",
    },
  },
  errorHandler(error) {
    console.error(
      "%cError",
      "background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;",
      error.message,
    );
  },
});

const httpLink = createHttpLink({
  uri: httpEndpoint,
});

const cache = new InMemoryCache({
  addTypename: true,
});

export const setupApolloClient = new ApolloClient({
  cache,
  link: headersLink.concat(httpLink),
});
