import {
  CellAbsence,
  CellAbsenceCreate,
  CellAbsenceCreateMany,
  CellAbsenceDelete,
  CellAbsenceUpdate,
  CellAbsenceUpdateMany,
  CellAbsences,
} from "@/graphql/planning/CellAbsences";
import { usePlanningStore } from "@/pinia/features/planning";
import { apolloProvider } from "@/vue-apollo";

export const deleteCellAbsence = async (payload: { where: any }) => {
  try {
    await apolloProvider.defaultClient.mutate({
      mutation: CellAbsenceDelete,
      update: (store, data) => {
        updateStoreAfterDeleteCellAbsence(store, data.data.DeleteCellshift.id);
      },
      variables: {
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateCellAbsence = async (payload: { data: any; where: any }) => {
  try {
    await apolloProvider.defaultClient.mutate({
      mutation: CellAbsenceUpdate,
      update: (store, data) => {
        updateStoreAfterUpdateCellAbsence(store, data.data.updateCellabsence);
      },
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const createCellAbsence = async (payload: { data: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: CellAbsenceCreate,
      update: (store, { data: { createCellabsence } }) => {
        updateStoreAfterCreateCellAbsence(store, createCellabsence);
      },
      variables: {
        data: payload.data,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const createManyCellAbsence = async (payload: { data: any }) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: CellAbsenceCreateMany,
      variables: {
        data: payload.data,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const updateManyCellAbsence = async (payload: {
  data: any;
  where: any;
}) => {
  try {
    return await apolloProvider.defaultClient.mutate({
      mutation: CellAbsenceUpdateMany,
      variables: {
        data: payload.data,
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

export const readCellAbsence = async (payload: { where: any }) => {
  try {
    return await apolloProvider.defaultClient.query({
      query: CellAbsence,
      variables: {
        where: payload.where,
      },
    });
  } catch (error) {
    console.error(error);
  }
};

const updateStoreAfterCreateCellAbsence = (store, createCellAbsence) => {
  const data = store.readQuery({
    query: CellAbsences,
    variables: usePlanningStore().apolloCacheVariables.cellAbsences,
  });
  data.CellAbsences.push(createCellAbsence);
  store.writeQuery({
    data,
    query: CellAbsences,
  });
};

const updateStoreAfterDeleteCellAbsence = (store, id) => {
  const planningStore = usePlanningStore();
  const data = store.readQuery({
    query: CellAbsences,
    variables: planningStore.apolloCacheVariables.cellAbsences,
  });
  const index = data.CellAbsences.findIndex((c) => c.id === id);
  if (index !== -1) {
    // Mutate cache result
    data.CellAbsences.splice(index, 1);
    // Write back to the cache
    store.writeQuery({
      data,
      query: CellAbsences,
    });
  }
};

const updateStoreAfterUpdateCellAbsence = (store, updateCellAbsences) => {
  if (!usePlanningStore().apolloCacheVariables.cellAbsences) return;
  const data = store.readQuery({
    query: CellAbsences,
    variables: usePlanningStore().apolloCacheVariables.cellAbsences,
  });
  const index = data.CellAbsences.findIndex(
    (c) => c.id === updateCellAbsences.id,
  );
  if (index !== -1) {
    // Mutate cache result
    if (updateCellAbsences.status === "active") {
      data.CellAbsences.splice(index, 1, updateCellAbsences);
    } else {
      data.CellAbsences.splice(index, 1);
    }
    // Write back to the cache
    store.writeQuery({
      data,
      query: CellAbsences,
    });
  }
};
