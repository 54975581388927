<template>
  <div>
    <v-btn
      :class="$style.infoCta"
      @click="
        dialog = true;
        getTheCurrentDocumentation();
      "
      color="info"
      fab
      small
    >
      <v-icon dark> mdi-information-variant </v-icon>
    </v-btn>
    <v-dialog
      :content-class="$style.sideDialog"
      :fullscreen="false"
      :persistent="true"
      close-delay="0"
      max-width="600"
      v-model="dialog"
    >
      <v-card flat v-if="!loadingData">
        <v-btn
          :class="$style.closeButton"
          @click="dialog = false"
          fab
          icon
          small
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-tabs align-with-title v-model="tab">
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab :key="`tab-${itemIndex}`" v-for="(item, itemIndex) in content">
            {{ item.tab }}
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item
            :key="`content-${contentIndex}`"
            v-for="(item, contentIndex) in content"
          >
            <v-card class="docWysiwygCtnr" flat>
              <v-card-text
                :class="$style.dynamicHeight"
                v-html="item.content"
                v-if="readOnly"
              ></v-card-text>
              <QuillWysiwyg
                :content="item.content"
                @step-data-update="updateContentData($event, item.tab)"
                v-else
              />
            </v-card>
          </v-tab-item>
        </v-tabs-items>
        <div class="d-flex justify-end pa-4">
          <template v-if="!readOnly">
            <v-btn @click="readOnly = !readOnly" class="mr-2">Cancel</v-btn>
            <v-btn
              :disabled="
                contentPayload.productDescription === content[0].content &&
                contentPayload.techDescription === content[1].content
              "
              @click="createContent"
              color="primary"
              v-if="!existingDocId"
              >Create</v-btn
            >
            <v-btn
              :disabled="
                contentPayload.productDescription === content[0].content &&
                contentPayload.techDescription === content[1].content
              "
              @click="updateContent"
              color="primary"
              v-else
              >Save</v-btn
            >
          </template>
          <v-btn @click="readOnly = !readOnly" color="success" v-else
            >Edit</v-btn
          >
        </div>
      </v-card>
      <v-card class="d-flex align-center justify-center h-100" v-else>
        <v-progress-circular
          :size="70"
          :width="7"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-card>
    </v-dialog>
  </div>
</template>

<script lang="ts">
import {
  type Ref,
  defineComponent,
  getCurrentInstance,
  reactive,
  ref,
} from "vue";

import QuillWysiwyg from "@/components/content/QuillWysiwyg.vue";
import useDocumentation from "@/modules/documentation/composables/use-documentation";

import type { Func } from "../utils/types.utils";

export default defineComponent({
  components: { QuillWysiwyg },
  name: "DocComponent",
  setup() {
    const ctx = getCurrentInstance();

    // STRUCTURE
    const dialog = ref(false);
    const readOnly = ref(true);
    const tab: Ref<null | number> = ref(null);
    const loadingData = ref(true);

    enum EContentTab {
      produit = "Produit",
      tech = "Tech",
    }

    const content = ref([
      {
        content: "",
        tab: EContentTab.produit,
      },
      {
        content: "",
        tab: EContentTab.tech,
      },
    ]) as Ref<
      [
        { content: string; tab: EContentTab.produit },
        { content: string; tab: EContentTab.tech },
      ]
    >;

    const existingDocId: Ref<null | string> = ref(null);

    const executeWithLoadingAndGoBackToReadOnly = async (callback: Func) => {
      loadingData.value = true;
      await callback();
      await getTheCurrentDocumentation();
      readOnly.value = true;
      loadingData.value = false;
    };

    // ACTIONS
    const { createDocumentation, getDocumentations, updateDocumentation } =
      useDocumentation();

    const contentPayload = reactive({
      productDescription: "",
      routeName: "",
      techDescription: "",
    });

    const updateContentData = (
      content: { html: string; text: string },
      tab: EContentTab,
    ) => {
      const keyToUpdate =
        tab === EContentTab.tech ? "techDescription" : "productDescription";
      contentPayload[keyToUpdate] = content.html;
    };

    const createContent = async () => {
      try {
        await executeWithLoadingAndGoBackToReadOnly(async () => {
          contentPayload.routeName = ctx?.proxy.$router.currentRoute.name!;
          if (!contentPayload.routeName) throw new Error("No route name found");
          const result = await createDocumentation(contentPayload);
          existingDocId.value = result.id;
        });
      } catch (error) {
        loadingData.value = false;
        throw new Error(`Error in createContent: ${error}`);
      }
    };

    const updateContent = async () => {
      try {
        await executeWithLoadingAndGoBackToReadOnly(async () => {
          contentPayload.routeName = ctx?.proxy.$router.currentRoute.name!;
          if (!contentPayload.routeName) throw new Error("No route name found");
          if (!existingDocId.value)
            throw new Error("No existing content id found");

          await updateDocumentation(existingDocId.value, contentPayload);
        });
      } catch (error) {
        loadingData.value = false;
        throw new Error(`Error in updateContent: ${error}`);
      }
    };

    // QUERIES
    const getTheCurrentDocumentation = async () => {
      loadingData.value = true;
      const routeName = ctx?.proxy.$router.currentRoute.name || "";
      const result = await getDocumentations(routeName);

      if (result[0]) {
        content.value = [
          {
            content: result[0].productDescription,
            tab: EContentTab.produit,
          },
          {
            content: result[0].techDescription,
            tab: EContentTab.tech,
          },
        ];

        contentPayload.productDescription = result[0].productDescription;
        contentPayload.techDescription = result[0].techDescription;

        existingDocId.value = result[0].id;
      } else {
        content.value = [
          {
            content: "",
            tab: EContentTab.produit,
          },
          {
            content: "",
            tab: EContentTab.tech,
          },
        ];
        contentPayload.productDescription = "";
        contentPayload.techDescription = "";
        existingDocId.value = null;
      }

      loadingData.value = false;
    };

    return {
      content,
      contentPayload,
      createContent,
      dialog,
      existingDocId,
      getTheCurrentDocumentation,
      loadingData,
      readOnly,
      tab,
      updateContent,
      updateContentData,
    };
  },
});
</script>

<style module lang="scss">
.infoCta {
  position: fixed;
  right: 10px;
  bottom: 50px;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
}

.sideDialog {
  background-color: white;
  margin: 0 !important;
  padding: 0;
  position: fixed;
  max-height: 100% !important;
  height: 100% !important;
  right: 0 !important;
}

.dynamicHeight {
  height: calc(100vh - 116px);
  max-height: calc(100vh - 116px);
  overflow: auto;
}
</style>

<style lang="scss">
.docWysiwygCtnr .ql-editor {
  height: calc(100vh - 186px);
  max-height: calc(100vh - 186px);
  overflow: auto;
}
</style>
