import type { Component } from "vue";
import type { TranslateResult } from "vue-i18n";

import { defineStore } from "pinia";

const COMPONENT_DEFAULT = undefined;
const PROPS_DEFAULT = undefined;
const PROPS_OPTIONS_DEFAULT: IPopinOptions = {
  closeDelay: 0,
  forceDestroy: true,
  fullscreen: false,
  maxWidth: "none",
  persistent: false,
  sideDialog: false,
  width: null,
};

export interface SnackbarPayload {
  color?: string;
  message: TranslateResult | string;
}
// interface Snackbar {
//   key: string;
//   message: string;
//   top?: boolean;
//   bottom?: boolean;
//   left?: boolean;
//   right?: boolean;
//   color?: string;
//   timeout: null | any;
//   transition?: string;
//   show: boolean;
// }

export interface IPopinOptions {
  closeDelay?: number | string;
  customZIndex?: number;
  forceDestroy?: boolean;
  fullscreen?: boolean;
  maxWidth?: number | string;
  persistent?: boolean;
  scrollable?: boolean;
  sideDialog?: boolean;
  width?: null | number | string;
  widthInPercentage?: boolean;
}

export const useUiStore = defineStore({
  actions: {
    buildComponentPopin(payload: {
      component: Component;
      options?: IPopinOptions;
      popinLevel?: number;
      props?: any;
    }) {
      let { popinLevel } = payload;

      if (!popinLevel) {
        popinLevel = 0;
      }
      // Position fixed parent
      const scrollY = window.scrollY;
      document.body.style.position = "fixed";
      document.body.style.top = `-${scrollY}px`;
      const thisRef = this as any;
      thisRef[`props${popinLevel}`] = payload.props;
      if (payload.options) {
        thisRef[`options${popinLevel}`] = {
          ...PROPS_OPTIONS_DEFAULT,
          ...payload.options,
        };
      } else {
        thisRef[`options${popinLevel}`] = PROPS_OPTIONS_DEFAULT;
      }
      thisRef[`component${popinLevel}`] = payload.component;
      thisRef[`popinToggle${popinLevel}`] = true;
    },
    clearSnackbars() {
      this.snackbars = [];
    },
    closePopin(popinLevel = 0) {
      // Position fixed parent
      const scrollY = document.body.style.top;
      document.body.style.position = "";
      document.body.style.top = "";
      window.scrollTo(0, Number.parseInt(scrollY || "0") * -1);
      const thisRef = this as any;
      thisRef[`popinToggle${popinLevel}`] = false;
    },
    closePopinAndDestroy(popinLevel = 0) {
      // Position fixed parent
      const scrollY = document.body.style.top;
      document.body.style.position = "";
      document.body.style.top = "";
      window.scrollTo(0, Number.parseInt(scrollY || "0") * -1);
      const thisRef = this as any;
      thisRef[`popinToggle${popinLevel}`] = false;
      thisRef[`component${popinLevel}`] = COMPONENT_DEFAULT;
      thisRef[`props${popinLevel}`] = PROPS_DEFAULT;
      thisRef[`options${popinLevel}`] = PROPS_OPTIONS_DEFAULT;
    },
    errorSnackbar(message: TranslateResult | string) {
      this.pushSnackbar({
        color: "#FA9594",
        message,
      });
    },
    infoSnackbar(message: TranslateResult | string) {
      this.pushSnackbar({
        color: "#2196F3",
        message,
      });
    },
    // Popin actions
    openPopin(popinLevel = 0) {
      // Position fixed parent
      const scrollY = window.scrollY;
      document.body.style.position = "fixed";
      document.body.style.top = `-${scrollY}px`;
      const thisRef = this as any;
      thisRef[`popinToggle${popinLevel}`] = true;
    },
    pushSnackbar(snackbar: SnackbarPayload) {
      this.snackbars.push(snackbar);
    },
    // UI actions
    setOverlay(payload: boolean) {
      this.overlay = payload;
    },
    setSnackbar(payload: boolean) {
      this.snackbarToggle = payload;
    },
    successSnackbar(message: TranslateResult | string) {
      this.pushSnackbar({
        color: "#1DBB8C",
        message,
      });
    },
    warningSnackbar(message: TranslateResult | string) {
      this.pushSnackbar({
        color: "#FFA800",
        message,
      });
    },
  },
  id: "useUi",
  state: () => ({
    building: false,
    component0: COMPONENT_DEFAULT as Component | undefined,
    component1: COMPONENT_DEFAULT as Component | undefined,
    options0: PROPS_OPTIONS_DEFAULT,
    options1: PROPS_OPTIONS_DEFAULT,
    overlay: false,
    popinToggle0: false,
    popinToggle1: false,
    props0: PROPS_DEFAULT,
    props1: PROPS_DEFAULT,
    snackbarToggle: false,
    snackbars: [] as SnackbarPayload[],
  }),
});
