import "@mdi/font/css/materialdesignicons.css";
import Vue from "vue";
import Vuetify from "vuetify";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi",
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        accent: "82B1FF",
        blue_dark: "083D77",
        blue_light: "EFFEFF",
        chip_blue: "cfdeff",
        chip_red: "ffb0af",

        chip_tale: "c4f6e9",
        chip_yellow: "ffeab6",
        doc_type_chip_blue: "D2DEFC",

        doc_type_chip_electric_blue: "D6EFFB",
        doc_type_chip_green: "D8F5C8",
        doc_type_chip_orange: "F9E3D7",
        doc_type_chip_red: "F9DDE5",
        doc_type_chip_rose: "F9DBF4",
        doc_type_chip_turquoise: "CCF4E9",
        doc_type_chip_yellow: "FBEABC",
        error: "FA9594",

        green: "338E73",
        green_soft: "89EE79",
        green_success: "00A78A",
        grey_color_background: "f5f5f5",
        grey_color_icon: "818386",
        grey_dark: "A0A4A8",
        grey_light: "efefef",
        grey_middle: "D5D5D5",
        // doc_type_chip_turquoise: "2ABCB6",
        // doc_type_chip_yellow: "F1C423",
        // doc_type_chip_green: "5F9585",
        // doc_type_chip_blue: "2868F0",
        // doc_type_chip_rose: "EA3ABD",
        // doc_type_chip_orange: "FAAD14",
        // doc_type_chip_red: "B33C86",

        h1: "2B2B2B",
        hover: "B297FF",
        info: "2196F3",
        ongoing: "2868F0",

        primary: "6733FD",
        primary_extra_light: "E1D6FF",
        primary_light: "F0E8FF",
        red: "FE4242",
        red_error: "E90101",
        red_light: "FFE4E4",
        secondary: "BBA0FE",
        selected: "6733FD",
        soft: "D7C6FE",
        success: "1DBB8C",
        success_light: "CCEDE8",
        ongoing_light: "DBEEFA",

        text: "2B2B2B",
        warning: "FFA800",

        yellow: "FFA800",
        yellow_light: "FFF2D8",

        critical: "a44345",
        high: "c69634",
        normal: "4b65f6",
        low: "89909d",
      },
    },
  },
});
