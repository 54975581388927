import { getAnalytics, logEvent } from "firebase/analytics";

import { useAuthentificationStore } from "@/pinia/user";

import type { UserID } from "../mercateam";

export const logAction = (name: string | undefined) => {
  logEvent(getAnalytics(), "action", {
    action_name: name,
  });
};

export const logOpen = (name: string | undefined, path: string) => {
  logEvent(getAnalytics(), "open", {
    action_name: name,
    action_path: path,
  });
  logEvent(getAnalytics(), "screen_view", {
    firebase_screen: name,
    firebase_screen_class: path,
  });
};

export const segmentIdentify = (id: UserID, userProperties?: any) => {
  (window as any).analytics.identify(id, userProperties);
};

export const segmentTriggerEvent = (
  eventName: string,
  data?: Record<string, any>,
) => {
  const user = useAuthentificationStore().data;
  const client = useAuthentificationStore().active_client;
  const site = useAuthentificationStore().active_site_full;
  (window as any).analytics.track(`${eventName}`, {
    category_job: user?.category_job,
    site_name: `${client?.name} / ${site?.name}`,
    user_id: user?.id,
    ...data,
  });
};

export const segmentTriggerPage = (eventName: string) => {
  const user = useAuthentificationStore().data;
  const client = useAuthentificationStore().active_client;
  const site = useAuthentificationStore().active_site_full;
  (window as any).analytics.page(`${eventName}`, {
    category_job: user?.category_job,
    site_name: `${client?.name} / ${site?.name}`,
    user_id: user?.id,
  });
};

export const sendNotification = (
  eventName: string,
  emails: string[],
  data: Record<string, any>,
) => {
  for (const email of emails) {
    (window as any).analytics.identify(email);
    (window as any).analytics.track(`mail_${eventName}`, {
      ...data,
      email,
    });
  }
};
