import { z } from "zod";

import { Id } from "@/shared/domain";

const CampaignApplicant = z.object({
  employee_id: Id,
  status: z.union([
    z.literal("to-be-started"),
    z.literal("on-going"),
    z.literal("completed"),
  ]),
  step_completed: z.number(),
});
type CampaignApplicant = z.infer<typeof CampaignApplicant>;

export const Campaign = z.object({
  applicants: CampaignApplicant.array(),
  creator_id: z.string().nullable(),
  due_date: z.string().nullable(),
  name: z.string(),
  start_date: z.string(),
  // must remove the default value when the status is add on the backend
  status: z
    .union([z.literal("started"), z.literal("completed")])
    .default("started"),
  // must remove the default value when the status is add on the backend
  step_count: z.number().default(10),
  template_id: z.string(),
  template_name: z.string(),
  template_revision: z.number(),
});

export type Campaign = z.infer<typeof Campaign>;

const CampaignApplicantDetail = z.object({
  factory_name: z.string(),
  first_name: z.string(),
  id: Id,
  last_name: z.string(),
  picture_url: z.string().nullable(),
  progress: z.number(),
  status: z.union([
    z.literal("to-be-started"),
    z.literal("on-going"),
    z.literal("completed"),
  ]),
  step_completed: z.number(),
  team_name: z.string(),
});
export type CampaignApplicantDetail = z.infer<typeof CampaignApplicantDetail>;

export const CampaignDetail = z.object({
  applicants: CampaignApplicantDetail.array(),
  completedApplicants: CampaignApplicantDetail.array(),
  dueDate: z.string().nullable(),
  creatorId: z.string(),
  name: z.string(),
  ongoingApplicants: CampaignApplicantDetail.array(),
  startDate: z.string(),
  status: z.union([z.literal("started"), z.literal("completed")]),
  stepCount: z.number().default(10),
  templateId: z.string(),
  templateName: z.string(),
  templateRevision: z.number(),
});

export type CampaignDetail = z.infer<typeof CampaignDetail>;
