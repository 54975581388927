<template>
  <div>
    <v-row align="center" justify="center">
      <v-col cols="6">
        <div class="text--primary">
          {{ $t("parameters.addFavoriteTeams") }}
        </div>
      </v-col>
      <v-col cols="6">
        <v-autocomplete
          :items="teams"
          :label="$t('parameters.searchTeam')"
          item-text="team_name"
          item-value="id"
          multiple
          v-model="teamsSelected"
          ><template #selection="{item, index}">
            <v-chip v-if="index === 0">
              <span>{{ textEllipsis(getTeamName(item.id), 10) }}</span>
            </v-chip>
            <span class="grey--text text-caption" v-if="index === 1">
              (+{{ teamsSelected.length - 1 }} {{ $t("global.others") }})
            </span>
          </template></v-autocomplete
        >
      </v-col>
    </v-row>
    <v-row cols="12">
      <div v-if="teamsSelected.length > 0">
        <v-chip-group
          :key="i"
          active-class="primary--text"
          v-for="(team, i) in teamsSelected"
        >
          <v-chip
            :key="`team-${team}${i}`"
            @click:close="teamsSelected.splice(i, 1)"
            class="ma-1"
            close
            close-icon="mdi-close"
            small
          >
            <span>{{ textEllipsis(getTeamName(team), 5) }} </span>
          </v-chip>
        </v-chip-group>
      </div>
    </v-row>
  </div>
</template>

<script lang="ts">
import { cloneDeep, isEqual, orderBy } from "lodash";
import { mapState, mapStores } from "pinia";
import Vue from "vue";

import type { ITeam, IUser } from "@/tscript/interfaces";
import type { TeamID } from "@/tscript/mercateam";

import { useTeamGlobalStore, useUserGlobalStore } from "@/pinia/collections";
import { useAuthentificationStore } from "@/pinia/user";
import { textEllipsis } from "@/tscript/utils/string";

export default Vue.extend({
  computed: {
    ...mapStores(
      useTeamGlobalStore,
      useAuthentificationStore,
      useUserGlobalStore,
    ),
    ...mapState(useTeamGlobalStore, {
      teamDictionnary: "dictionary",
    }),
    currentUser(): IUser | null {
      if (this.useAuthentificationStore.data?.id) {
        return this.useAuthentificationStore.data;
      }
      return null;
    },
    teams(): ITeam[] {
      return orderBy(this.useTeamGlobalStore.actives, "team_name");
    },
  },
  data() {
    return {
      originalTeams: [] as TeamID[],
      teamsSelected: [] as TeamID[],
      textEllipsis,
    };
  },
  methods: {
    getTeamName(teamId: TeamID): string {
      if (!this.teamDictionnary || !teamId) return "";
      const team: ITeam | undefined = this.teamDictionnary[teamId];
      if (!team) return "";
      return `${team.team_name}`;
    },
  },
  mounted() {
    this.originalTeams = this.currentUser?.favorite_teams || [];
    this.teamsSelected = cloneDeep(this.originalTeams);
  },
  name: "ContentTeamData",
  watch: {
    teamsSelected(value) {
      if (isEqual(this.originalTeams, value)) {
        this.$emit("has-changed", false);
      } else {
        this.$emit("has-changed", true);
        this.$emit("team-selected", value);
      }
    },
  },
});
</script>
