import {
  type Dictionary,
  chain,
  filter,
  find,
  groupBy,
  includes,
  isEmpty,
  map,
  orderBy,
  sortBy,
} from "lodash";
import { defineStore } from "pinia";

import type {
  LAbsence,
  LClearance,
  LPlanning,
  LPosition,
  LShift,
  LSkill,
  LTraining,
} from "@/tscript/links_employee";
import type {
  EmployeeID,
  PositionID,
  RestrictionID,
  SkillID,
  SubpositionID,
  TeamID,
} from "@/tscript/mercateam";

import * as baseCollection from "@/pinia/collections";
import { useAuthentificationStore } from "@/pinia/user";
import {
  BaseStatus,
  type EBaseStatus,
  type IContract,
  type IEmployee,
  type IEmployeeCategory,
  type IPosition,
  type IPositionLevel,
  type IShift,
  type ITeam,
} from "@/tscript/interfaces";
import { createDictionary } from "@/tscript/utils/dictionary";

import {
  useEmployeeClearanceStore,
  useEmployeeGlobalStore,
  useEmployeeSkillStore,
  useEmployeeTrainingStore,
  usePositionGlobalStore,
  usePositionLevelGlobalStore,
  useTeamGlobalStore,
} from "../collections";
import { useTeamDataStore } from "../team";

export interface EmployeeCardLight {
  categories?: IEmployeeCategory[];
  category?: IEmployeeCategory;
  contract?: IContract;
  employee_company_id: string;
  first_letter_name: string;
  first_name: string;
  full_name: string;
  id: EmployeeID;
  isExternal: boolean;
  last_name: string;
  picture_url: string;
  status: EBaseStatus;
  team_id: string;
  team_name: string;
}

export interface EmployeeCardFull extends EmployeeCardLight {
  absences: LAbsence[];
  clearances:
    | []
    | {
      clearance_id: SkillID;
      end_date: any;
    }[];
  planning: LPlanning[];
  restrictions: [] | RestrictionID[];
  shifts: LShift[];
  skills:
    | []
    | {
      end_date: any;
      skill_id: SkillID;
    }[];
  trainings: LTraining[];
}

export const useTeamDataFastStore = defineStore({
  getters: {
    employeesByTeams(): Dictionary<IEmployee[]> {
      const EmployeeGlobalStore = useEmployeeGlobalStore();

      const list: IEmployee[] = [];
      const dictionary: Dictionary<IEmployee[]> = {};

      for (const employee of EmployeeGlobalStore.actives || []) {
        list.push(employee);
      }

      const groupByTeams = groupBy(list, "team_id");
      for (const [team, data] of Object.entries(groupByTeams)) {
        if (dictionary[team]) {
          dictionary[team].push(...data);
        } else {
          dictionary[team] = data;
        }
      }

      return dictionary;
    },
    employeesOnlyTeam(): {
      dictionary: Dictionary<IEmployee>;
      employeeCardsFull: EmployeeCardFull[];
      employeeCardsLight: EmployeeCardLight[];
      employeeIds: EmployeeID[];
      employeeIdsWithoutLoan: EmployeeID[];
      list: IEmployee[];
      listWithoutLoan: IEmployee[];
    } {
      const list: IEmployee[] = [];
      const employeeCardsLight: EmployeeCardLight[] = [];
      const employeeCardsFull: EmployeeCardFull[] = [];
      const dictionary = {};
      const employeeIds = [];
      const listWithoutLoan: IEmployee[] = [];
      const employeeIdsWithoutLoan = [];

      if (!this.team_id) {
        return {
          dictionary,
          employeeCardsFull,
          employeeCardsLight,
          employeeIds,
          employeeIdsWithoutLoan,
          list,
          listWithoutLoan,
        };
      }

      const TeamGlobalStoreDictionary = useTeamGlobalStore().dictionary;
      if (!TeamGlobalStoreDictionary || isEmpty(TeamGlobalStoreDictionary)) {
        return {
          dictionary,
          employeeCardsFull,
          employeeCardsLight,
          employeeIds,
          employeeIdsWithoutLoan,
          list,
          listWithoutLoan,
        };
      }

      const EmployeeGlobalStore = useEmployeeGlobalStore();
      const AuthentificationStore = useAuthentificationStore();
      const lastname_first = AuthentificationStore.data?.firstname_parameter;
      // Team always the same for this list
      const currentTeam = TeamGlobalStoreDictionary[this.team_id];
      if (!currentTeam) throw new Error("Team is not defined. It should be");

      // const ContractDictionary = useContractGlobalStore().dictionary;
      // if (!ContractDictionary)
      //   throw new Error("Contract Dictionnary is not defined");

      const CategoryDictionnary =
        baseCollection.useParameterEmployeeCategoryStore().dictionary;
      if (!CategoryDictionnary)
        throw new Error("Category Employee Dictionnary is not defined");

      // Set or uniq values
      const uniqEmployeeIds = new Set<EmployeeID>([]);
      const uniqEmployeeIdsWithoutLoan = new Set<EmployeeID>([]);

      // Positions
      const positions: IPosition[] =
        baseCollection.usePositionGlobalStore().actives || [];
      const positionIds = map(positions, (p) => p.id);

      // LPLANNING (planning)
      const EmployeePlanningDictionary =
        baseCollection.useEmployeePlanningStore().dictionaryByEmployees;

      // LABSENCES (planning)
      const EmployeeAbsenceDictionary =
        baseCollection.useEmployeeAbsencesStore().dictionaryByEmployees;
      // LTRAININGS (team / evaluation)

      // TODO Filter by includes(employeesFiltered) first ?
      const EmployeeTrainingDictionary =
        baseCollection.useEmployeeTrainingStore().dictionaryByEmployees;
      // LSKILLS (team / temporary global)
      const EmployeeSkillDictionary =
        baseCollection.useEmployeeSkillStore().dictionaryByEmployees;
      // LClearances (team / temporary global)
      const EmployeeClearanceDictionary =
        baseCollection.useEmployeeClearanceStore().dictionaryByEmployees;
      // LRestrictions (team / temporary global)
      const EmployeeRestrictionDictionary =
        baseCollection.useEmployeeRestrictionStore().dictionaryByEmployees;
      // LShift (planning)
      const EmployeeShiftDictionary =
        baseCollection.useEmployeeShiftStore().dictionaryByEmployees;

      const employeesFromPlanning =
        this.employeeIds && this.employeeIds.length > 0
          ? filter(
            AuthentificationStore.active_site_full
              ?.settings_planning_archived_employees
              ? [
                  ...(EmployeeGlobalStore.actives || []),
                  ...(EmployeeGlobalStore.archived || []),
                ]
              : EmployeeGlobalStore.actives,
            (e) => includes(this.employeeIds, e.id),
          )
          : EmployeeGlobalStore.actives;

      for (const employee of employeesFromPlanning || []) {
        uniqEmployeeIds.add(employee.id);
        uniqEmployeeIdsWithoutLoan.add(employee.id);
        list.push(employee);
        listWithoutLoan.push(employee);
        const fullname = lastname_first
          ? `${employee.last_name} ${employee.first_name}`
          : `${employee.first_name} ${employee.last_name}`;
        const employeeCardLight: EmployeeCardLight = {
          //     : undefined,
          categories: employee.categories,
          contract: employee.contract ? employee.contract : undefined,
          employee_company_id: employee.employee_company_id,
          first_letter_name: employee.first_name.charAt(0),
          first_name: employee.first_name,
          full_name: fullname,
          id: employee.id,
          isExternal: false,
          last_name: employee.last_name,
          picture_url: employee.picture_url,
          status: employee.status,
          // category:
          //   employee.category_id && CategoryDictionnary[employee.category_id]
          //     ? CategoryDictionnary[employee.category_id]
          team_id: currentTeam.id,
          team_name: currentTeam.team_name,
        };

        const employeeCardFull: {
          absences: [] | LAbsence[];
          clearances:
            | []
            | {
              clearance_id: SkillID;
              end_date: any;
            }[];
          planning: [] | LPlanning[];
          restrictions: [] | RestrictionID[];
          shifts: [] | LShift[];
          skills:
            | []
            | {
              end_date: any;
              skill_id: SkillID;
            }[];
          trainings: [] | LTraining[];
        } = {
          absences: EmployeeAbsenceDictionary[employee.id]
            ? EmployeeAbsenceDictionary[employee.id]
            : [],
          clearances: EmployeeClearanceDictionary[employee.id]
            ? chain(EmployeeClearanceDictionary[employee.id])
              .filter((e) => {
                return e.status === "active" || e.status === undefined;
              })
              .map((e) => {
                return { clearance_id: e.clearance_id, end_date: e.end_date };
              })
              .value()
            : [],
          // TODO LOW PERFORMANCE. REMOVE WHEN POSTGRESQL
          planning: EmployeePlanningDictionary[employee.id]
            ? filter(EmployeePlanningDictionary[employee.id], (lep) => {
              return includes(positionIds, lep.position_id);
            })
            : [],
          restrictions: EmployeeRestrictionDictionary[employee.id]
            ? chain(EmployeeRestrictionDictionary[employee.id])
              .filter((e) => {
                return (
                  e.status === BaseStatus.active || e.status === undefined
                );
              })
              .map((e) => e.restriction_id)
              .value()
            : [],
          shifts: EmployeeShiftDictionary[employee.id]
            ? EmployeeShiftDictionary[employee.id].filter(
              (restriction) =>
                restriction.status === BaseStatus.active ||
                restriction.status === undefined,
            )
            : [],
          skills: EmployeeSkillDictionary[employee.id]
            ? chain(EmployeeSkillDictionary[employee.id])
              .filter((e) => {
                // TODO Remove 'as any' after implementing the types
                return (
                  (e as any).status === "active" || e.status === undefined
                );
              })
              .map((e) => {
                return { end_date: e.end_date, skill_id: e.skill_id };
              })
              .value()
            : [],
          trainings: EmployeeTrainingDictionary[employee.id]
            ? filter(
              EmployeeTrainingDictionary[employee.id] as LTraining[],
              (e) => {
                // Only ongoing trainings.
                return (
                  ((e.status as any) === "active" ||
                    e.status === undefined) &&
                    !e.validated
                );
              },
            )
            : [],
        };
        employeeCardsLight.push(employeeCardLight);
        employeeCardsFull.push({ ...employeeCardLight, ...employeeCardFull });
      }
      return {
        dictionary,
        employeeCardsFull,
        employeeCardsLight: orderBy(employeeCardsLight, "last_name"),
        employeeIds: Array.from(uniqEmployeeIds),
        employeeIdsWithoutLoan: Array.from(uniqEmployeeIdsWithoutLoan),
        list,
        listWithoutLoan,
      };
    },
    // Use getters because of cache and single loop.
    linkEmployeeClearances(): {
      dictionary: Dictionary<LClearance[]>;
      list: LClearance[];
    } {
      const list: LClearance[] = [];
      const dictionary: Dictionary<LClearance[]> = {};

      const EmployeeClearances = useEmployeeClearanceStore();
      const employeeIds = this.employeesOnlyTeam.employeeIds;

      if (!employeeIds || employeeIds.length === 0)
        return {
          dictionary: {},
          list,
        };

      for (const clearance of EmployeeClearances.actives || []) {
        if (employeeIds.includes(clearance.employee_id)) {
          list.push(clearance);
          if (this.linkEmployeeClearancesOnlyTeam) {
            this.linkEmployeeClearancesOnlyTeam.push(clearance);
          } else {
            this.linkEmployeeClearancesOnlyTeam = [clearance];
          }

          if (dictionary[clearance.employee_id]) {
            dictionary[clearance.employee_id].push(clearance);
          } else {
            dictionary[clearance.employee_id] = [clearance];
          }
        }
      }

      return {
        dictionary,
        list,
      };
    },
    // Use getters because of cache and single loop.
    linkEmployeeSkills(): {
      dictionary: Dictionary<LSkill[]>;
      list: LSkill[];
    } {
      const list: LSkill[] = [];
      const dictionary: Dictionary<LSkill[]> = {};

      const EmployeeSkills = useEmployeeSkillStore();
      const employeeIds = this.employeesOnlyTeam.employeeIds;

      if (!employeeIds || employeeIds.length === 0)
        return {
          dictionary: {},
          list,
        };

      for (const skill of EmployeeSkills.actives || []) {
        if (employeeIds.includes(skill.employee_id)) {
          list.push(skill);
          if (dictionary[skill.employee_id]) {
            dictionary[skill.employee_id].push(skill);
          } else {
            dictionary[skill.employee_id] = [skill];
          }
        }
      }
      return {
        dictionary,
        list,
      };
    },
    // Use getters because of cache and single loop.
    linkEmployeeTrainings(): {
      list: LTraining[];
      onGoing: LTraining[];
      validated: LTraining[];
    } {
      const list: LTraining[] = [];
      const onGoing: LTraining[] = [];
      const validated: LTraining[] = [];

      const EmployeeTrainings = useEmployeeTrainingStore();
      const employeeIds = this.employeesOnlyTeam.employeeIds;
      if (!employeeIds || employeeIds.length === 0)
        return {
          list,
          onGoing,
          validated,
        };

      //
      for (const training of EmployeeTrainings.actives || []) {
        if (employeeIds.includes(training.employee_id)) {
          list.push(training);
          if (training.validated) {
            validated.push(training);
          } else {
            onGoing.push(training);
          }
        }
      }
      return {
        list,
        onGoing,
        validated,
      };
    },
    linkEmpoyeePositionsByTeams(): Dictionary<LPosition[]> {
      return {};
      // const EmployeePositionStore = useEmployeePositionStore();
      // const EmployeeGlobalStore = useEmployeeGlobalStore();

      // const dictionary: Dictionary<LPosition[]> = {};

      // const postionsWithTeamID: LPositionWithTeamID[] = chain(
      //   EmployeePositionStore.list,
      // )
      //   .map((position) => {
      //     const employeeData = find(EmployeeGlobalStore.list, (employee) => {
      //       return (
      //         employee.id === position.employee_id &&
      //         (employee.status === BaseStatus.active ||
      //           employee.status === undefined)
      //       );
      //     });
      //     if (employeeData) {
      //       return {
      //         ...position,
      //         team_id: employeeData?.team_id,
      //       };
      //     } else {
      //       return null;
      //     }
      //   })
      //   .compact()
      //   .value();

      // const groupByTeams = groupBy(postionsWithTeamID, "team_id");
      // for (const [team, data] of Object.entries(groupByTeams)) {
      //   if (dictionary[team]) {
      //     dictionary[team].push(...data);
      //   } else {
      //     dictionary[team] = data;
      //   }
      // }

      // return dictionary;
    },

    positions(): any[] {
      return filter(usePositionGlobalStore().actives, (p: any) => {
        const team_ids = map(p.teams, (t) => t.id);
        return includes(team_ids, this.team_id);
      });
    },

    positionsByTeams(): Dictionary<IPosition[]> {
      const PositionStore = usePositionGlobalStore();
      const SubpositionStore = usePositionLevelGlobalStore();

      const list: IPosition[] = [];
      const dictionary: Dictionary<IPosition[]> = {};

      // Get active Positions
      const positions = PositionStore.actives;

      // Case with 0 Subpositions
      if (SubpositionStore.actives?.length === 0) {
        for (const position of PositionStore.list) {
          list.push(position);
        }

        const groupByTeams = groupBy(list, "team_ids");
        for (const [teams, data] of Object.entries(groupByTeams)) {
          for (const team of teams) {
            if (dictionary[team]) {
              dictionary[team].push(...data);
            } else {
              dictionary[team] = data;
            }
          }
        }

        return dictionary;
      }

      for (const position of positions || []) {
        const subPositions =
          SubpositionStore.actives?.filter(
            ({ position_parent_id }) => position_parent_id === position.id,
          ) || [];
        if (subPositions.length > 0) {
          const subpositionOrderByName = orderBy(subPositions, "name");
          for (const [i, sub] of subpositionOrderByName.entries()) {
            const subposition = {
              ...sub,
              parent_name: position.name,
              sub_row_span: i === 0 ? subPositions.length : 0,
            };
            list.push(subposition);
          }
        } else {
          list.push(position);
        }
      }

      const groupByTeams = groupBy(list, "team_ids");
      for (const [teams, data] of Object.entries(groupByTeams)) {
        const currentTeams = teams.split(",");

        for (const team of currentTeams) {
          if (dictionary[team]) {
            dictionary[team].push(...data);
          } else {
            dictionary[team] = data;
          }
        }
      }

      return dictionary;
    },

    positionsOnlyTeam(): {
      dictionary: Dictionary<IPosition | IPositionLevel | undefined>;
      ids: (PositionID | SubpositionID)[];
      list: (IPosition | IPositionLevel)[];
      listLight: {
        cover?: null | string;
        id: PositionID | SubpositionID;
        name: string;
        type: "position" | "subposition";
      }[];
      positionGroupedById: Dictionary<IPosition>;
      subpositionsGroupedByParentId: Dictionary<IPositionLevel[]>;
    } {
      // Empty result
      const result: {
        dictionary: Dictionary<IPosition | IPositionLevel | undefined>;
        ids: (PositionID | SubpositionID)[];
        list: (IPosition | IPositionLevel)[];
        listLight: {
          cover?: null | string;
          id: PositionID | SubpositionID;
          name: string;
          type: "position" | "subposition";
        }[];
        positionGroupedById: Dictionary<IPosition>;
        subpositionsGroupedByParentId: Dictionary<IPositionLevel[]>;
      } = {
        dictionary: {},
        ids: [],
        list: [],
        listLight: [],
        positionGroupedById: {},
        subpositionsGroupedByParentId: {},
      };

      const teamDataStore = useTeamDataStore();
      // const PositionStore = usePositionStore();
      // const SubpositionStore = useSubpositionStore();
      const positionsSorted: IPosition[] = [];

      // Get Ordered Positions
      const positionOrders = teamDataStore.positionOrders;
      const positions = this.positions;

      if (!positionOrders || positionOrders.length <= 0) {
        positionsSorted.push(...sortBy(positions, ["name"]));
      } else {
        const order = positionOrders.map((positionOrder) =>
          positionOrder.position_id ? positionOrder.position_id : positionOrder,
        );

        positionsSorted.push(
          ...sortBy(positions, (p) => {
            return order.indexOf(p.id);
          }),
        );
      }

      // Case with 0 Subpositions
      if (this.subpositions.length === 0) {
        for (const position of positionsSorted) {
          result.list.push(position);
          result.listLight.push({
            cover: position?.cover,
            id: position.id,
            name: position.name,
            type: "position",
          });
          result.ids.push(position.id);
          result.positionGroupedById[position.id] = position;
        }
        result.dictionary = createDictionary(result.list);
        return result;
      }

      for (const position of positionsSorted) {
        const subPositions = this.subpositions.filter(
          (subposition: any) => subposition.position.id === position.id,
        );
        if (subPositions.length > 0) {
          const subpositionOrderByName = orderBy(subPositions, "name");
          for (const [i, sub] of subpositionOrderByName.entries()) {
            const subposition = {
              ...sub,
              parent_name: position.name,
              sub_row_span: i === 0 ? subPositions.length : 0,
            };
            result.list.push(subposition);
            result.listLight.push({
              cover: subposition?.cover,
              id: subposition.id,
              name: `${position.name}/${subposition.name}`,
              type: "subposition",
            });
            result.ids.push(subposition.id);
            if (
              result.subpositionsGroupedByParentId[
                (subposition as any).position.id
              ]
            ) {
              result.subpositionsGroupedByParentId[
                (subposition as any).position.id
              ].push(subposition);
            } else {
              result.subpositionsGroupedByParentId[
                (subposition as any).position.id
              ] = [subposition];
            }
          }
        } else {
          result.list.push(position);
          result.listLight.push({
            id: position.id,
            name: `${position.name}`,
            type: "position",
          });
          result.ids.push(position.id);
        }
        result.positionGroupedById[position.id] = position;
      }

      result.dictionary = createDictionary(result.list);
      return result;
    },

    subpositions(): any[] {
      return filter(usePositionLevelGlobalStore().actives, (s: any) => {
        const team_ids = map(s.teams, (t) => t.id);
        return includes(team_ids, this.team_id);
      });
    },

    team(): ITeam | undefined {
      const teams = useTeamGlobalStore().actives;
      const team = find(teams, (t) => t.id === this.team_id);
      return team;
    },

    teamLeaderNames(): string {
      const team = this.team;
      if (!team) return "";
      // Récupere les `team_leader_ids`
      const team_leader_ids = team.team_leaders?.map(
        (team_leader) => team_leader.id,
      );
      if (!team_leader_ids) return "";
      // Trouver les utilisateurs correspondant aux IDs
      const users = baseCollection.useUserGlobalStore().actives;
      const team_leaders = filter(users, (user) => {
        return includes(team_leader_ids, user.id);
      });
      if (team_leaders.length === 0) return "";
      // Renvoyer uniquement la chaine de caractère first_name last_name
      const namesArray = map(team_leaders, (team_leader) => {
        return ` ${team_leader.first_name} ${team_leader.last_name}`;
      });
      return namesArray.join(", ");
    },
  },
  id: "useTeamDataFast",
  state: () => {
    return {
      employeeCardsFull: null as EmployeeCardFull[] | null,
      // EmployeeCards
      employeeCardsLight: null as EmployeeCardLight[] | null,
      employeeIds: null as EmployeeID[] | null,
      employeesWithoutLoan: [] as IEmployee[],
      linkEmployeeAbsencesOnlyTeam: null as LAbsence[] | null,
      linkEmployeeAbsencesOnlyTeamDictionary: null as Dictionary<
        LAbsence[]
      > | null,
      linkEmployeeClearancesOnlyTeam: null as LClearance[] | null,
      linkEmployeeClearancesOnlyTeamDictionary: null as Dictionary<
        LClearance[]
      > | null,
      linkEmployeePlanningOnlyTeam: null as LPlanning[] | null,
      // linkEmployeeTrainingOnlyTeam: null as null | LTraining[],
      linkEmployeePlanningOnlyTeamDictionary: null as Dictionary<
        LPlanning[]
      > | null,
      linkEmployeeShiftOnlyTeam: null as LShift[] | null,
      linkEmployeeShiftOnlyTeamDictionary: null as Dictionary<LShift[]> | null,
      // Link
      linkEmployeeSkillsOnlyTeam: null as LSkill[] | null,
      // Dictionnaries
      linkEmployeeSkillsOnlyTeamDictionary: null as Dictionary<LSkill[]> | null,
      // linkEmployeeTrainingOnlyTeamDictionary: null as null | Dictionary<
      //   LTraining[]
      // >,
      // Primary
      positionsOnlyTeamState: null as IPosition[] | null,
      shitsOnlyTeam: null as IShift[] | null,
      subpositionsOnlyTeam: null as IPositionLevel[] | null,
      team_id: null as TeamID | null,
    };
  },
});
