import { z } from "zod";

import { Id } from "@/shared/domain";

export const Template = z.object({
  createdAt: z.date(),
  creatorId: z.string(),
  archived: z.boolean(),
  id: Id,
  name: z.string(),
  revision: z.number(),
  revisions: z.array(
    z.object({
      createdAt: z.date(),
      creatorId: z.string(),
      name: z.string(),
      revision: z.number(),
    }),
  ),
});

export type Template = z.infer<typeof Template>;
